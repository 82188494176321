
const invoicesLinks = [
  {
    path: '/invoices/reports',
    title: 'Atskaites',
    permission: 'manage_invoice_reports'
  },
  {
    path: '/invoices/credit_notes',
    title: 'Kredītrēķini',
    permission: 'view_invoices'
  },
  {
    path: '/invoices',
    title: 'Izrakstītie',
    permission: 'view_invoices'
  },
  {
    path: '/invoices/export',
    title: 'XML Eksports',
    permission: 'manage_xml'
  }
]

export default invoicesLinks
