<template>
    <Content :links="links" :filter="true" :showFilterDefault="true">

        <template v-slot:filter>
            <div class="flex flex-wrap items-center">
                <div class="flex flex-wrap sm:flex-nowrap items-center mr-3  ">
                    <div class="w-48">
                        <Input type="date" v-model="filter.from" @change="getItems"/>
                    </div>
                    <span class="p-1">-</span>
                    <div class="w-48">
                        <Input type="date" v-model="filter.to" @change="getItems"/>
                    </div>
                </div>

                <button type="button"
                        @click="firstDayOfYear"
                        class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 dark:border-gray-500 shadow-sm text-xs font-medium rounded text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 my-auto mr-2">
                    No gada sākuma
                </button>

                <button type="button"
                        @click="firstDayOfMonth"
                        class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 dark:border-gray-500 shadow-sm text-xs font-medium rounded text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 my-auto mr-2">
                    No mēneša sākuma
                </button>
                <button type="button"
                        @click="firstDayOfWeek"
                        class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 dark:border-gray-500 shadow-sm text-xs font-medium rounded text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 my-auto mr-2">
                    No nedēļas sākuma
                </button>
                <button type="button"
                        @click="today"
                        class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 dark:border-gray-500 shadow-sm text-xs font-medium rounded text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 my-auto mr-2">
                    Šodien
                </button>

                <button type="button"
                        @click="getReportPdf"
                        class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 dark:border-gray-500 shadow-sm text-xs font-medium rounded text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 my-auto mr-2">
                    Drukāt PDF
                </button>

                <button type="button"
                        @click="getReportExcel"
                        class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 dark:border-gray-500 shadow-sm text-xs font-medium rounded text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 my-auto mr-2">
                    Drukāt Excel
                </button>

            </div>
        </template>

        <template v-slot:content>
            <div>
                <template v-if="invoices && invoices.data.length > 0">
                    <div class="sm:rounded-md mb-3">
                        <ul role="list" class="">
                            <div class="rounded-lg shadow-lg mb-10 -mt-6">
                                <item-card>
                                    <template v-slot:title>
                                        <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                                            Kopā par izvēlēto periodu
                                        </h3>
                                    </template>
                                    <template v-slot:content>
                                        <item-text title="Kopā ieņēmumi EUR" :text="invoices.total_income"
                                                   textClass="font-semibold"/>
                                        <item-text title="Kopā iepirkta prece EUR" :text="invoices.total_purchase"
                                                   textClass="font-semibold"/>
                                        <item-text title="Piegādes izdevumi EUR" :text="invoices.total_shipping_costs"
                                                   textClass="font-semibold"/>
                                        <item-text title="Peļņa EUR" :text="invoices.total_difference_with_shipping"
                                                   textClass="font-semibold"/>
                                        <item-text title="Pārdotas vienības" :text="invoices.order_items_sum_quantity"
                                                   textClass="font-semibold"/>
                                    </template>
                                </item-card>
                            </div>

                            <template v-for="invoice in invoices.data" :key="invoice.id">
                                <ShowInvoice :invoice="invoice"/>
                            </template>
                        </ul>
                    </div>
                </template>

                <template v-else>
                    <p class="py-6 text-center dark:text-gray-300">
                        Diemžēl nekas netika atrasts
                    </p>
                </template>
            </div>
        </template>
    </Content>
</template>

<script>
import {debounce} from 'vue-debounce'
import Search from "@/services/Helpers/search";
import {mapGetters} from "vuex";
import invoicesLinks from "../../library/appbar/invoicesLinks";
import ShowInvoice from "@/components/Invoices/ShowInvoice"
import Input from "@/components/Components/Input"
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"
import Dates from "@/services/Helpers/dates";

export default {
    name: "Invoices",
    components: {
        ShowInvoice,
        Input,
        ItemCard,
        ItemText,
    },
    data: () => ({
        links: invoicesLinks,
        search: '',
        filter: {
            from: Dates.firstDayOfMonth(),
            to: new Date().toISOString().substr(0, 10),
        },
        queryParams: null,
    }),
    created() {
        this.$store.dispatch("setDataLoading", true)
        this.$store.dispatch('resetSearch')

        // Get query params from URL
        this.queryParams = this.$route.query

        // If there are no URL params set default params
        if (Object.keys(this.queryParams).length === 0) {
            this.$router.replace({
                query: {
                    from: this.filter.from,
                    to: this.filter.to,
                    page: Search.getCurrentPage(),
                    q: this.search
                }
            })
        }
        // Update component data from query prams
        this.setFiltersFromParams()
        this.searchInvoices()
    },
    computed: {
        ...mapGetters({
            invoices: 'invoices',
            formsForDisplay: 'formsForDisplay',
        })
    },
    watch: {
        search: function () {
            this.searchInvoices()
        }
    },
    methods: {
        getItems() {
            this.$router.replace({
                query: {
                    from: this.filter.from,
                    to: this.filter.to,
                    page: 1,
                    q: this.queryParams.q
                }
            })
            this.queryParams = this.$route.query
            this.searchInvoices()
        },
        setFiltersFromParams() {
            this.filter.from = this.queryParams.from ? this.queryParams.from : this.filter.from
            this.filter.to = this.queryParams.to ? this.queryParams.to : this.filter.to
        },
        searchInvoices: debounce(function () {
            this.$store.dispatch('getInvoices', this.queryParams)
        }, 500),
        firstDayOfYear() {
            this.filter.from = Dates.firstDayOfYear()
            this.getItems()
        },
        firstDayOfMonth() {
            this.filter.from = Dates.firstDayOfMonth()
            this.getItems()
        },
        firstDayOfWeek() {
            this.filter.from = Dates.firstDayOfWeek()
            this.getItems()
        },
        today() {
            this.filter.from = Dates.today()
            this.getItems()
        },
        getReportPdf() {
            this.$store.dispatch('getOrderInvoiceReportPdf', {
                from: this.filter.from,
                to: this.filter.to,
            })
        },
        getReportExcel() {
            this.$store.dispatch('getOrderInvoicesReportExcel', {
                from: this.filter.from,
                to: this.filter.to,
            })
        },
    },
}
</script>

<style>

</style>