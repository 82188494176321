<template>
  <li>
    <item-card :url="`/invoices/${invoice.id}`">
      <template v-slot:title>
        <div class="gap-2 md:flex items-center">
          <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
            {{invoice.customer}}
          </h3>

          <Badge v-if="invoice.payment_date" :text="'Apmaksāts ' + invoice.payment_date"
                 :className="`bg-green-600 text-white text-xs`"
          />
        </div>

      </template>
      <template v-slot:buttons>

          <template v-if="invoice.allow_download_excel ">
              <Button icon="briefcase-download" @click="downloadExcel()" tooltip="Lejuplādēt excel pielikumu" />
          </template>

        <template v-if="!loading">
          <Button icon="download" @click="downloadInvoicePdf()" tooltip="Lejuplādēt rēķinu" />
        </template>
        <template v-else>
          <Loading />
        </template>

        <template v-if="invoice.allow_delete && userHavePermission('manage_invoices')">
          <Button icon="delete" @click="deleteInvoice(invoice.id)" />
        </template>

      </template>
      <template v-slot:content>
        <item-text title="Rēķina nr" :text="invoice.uuid" />
        <item-text title="Datums" :text="invoice.invoice_date" />
        <item-text title="Summa Eur" :text="invoice.total" :textClass="'font-bold'"/>
        <item-text title="PVN EUR" :text="invoice.vat" />
        <item-text title="Kopā EUR" :text="invoice.total_with_vat" />

        <item-text title="Kopā iepirkta prece EUR" :text="invoice.total_purchase" />
        <item-text title="Peļņa EUR" :text="invoice.total_difference_with_shipping" :textClass="'font-bold'"/>
        <item-text title="Vienības" :text="invoice.order_items_sum_quantity" :textClass="'font-bold'"/>

        <template v-if="invoice.sent_by_mail">
          <item-text title="Rēķins izsūtīts">
            <template v-slot:content>
              <Badge :text="invoice.sent_by_mail" class="bg-green-100 text-green-800 mr-2 mb-2 md:mb-0" />
            </template>
          </item-text>
        </template>

      </template>
    </item-card>
  </li>
</template>

<script>
import {mapGetters} from "vuex";
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"
import Badge from "@/components/Components/Badge"
import Loading from "@/components/Components/Loading";

export default {
  name: "ShowInvoice",
  data() {
    return {
      showContact: false,
      showBranch: false,
    }
  },
  components: {
    ItemCard,
    ItemText,
    Loading,
    Badge,
  },
  computed: {
    ...mapGetters({
        loading: 'loading',
    })
  },
  props: {
    invoice: {
      type: Object,
      required: true
    }
  },
  methods: {
    userHavePermission(permission){
      return this.$userCan(permission)
    },
    downloadInvoice(invoice){
      this.$store.dispatch('getInvoicePdf', invoice)
    },
    deleteInvoice(itemId){
      if(confirm('Vai esi drošs?')){
        this.$Progress.start()
        this.$store.dispatch('deleteInvoice', itemId)
          .then(response => {
            this.$store.dispatch('getInvoices', this.invoices.meta.current_page)
          })
      }
    },
    downloadInvoicePdf() {
        this.$store.dispatch('getOrderInvoicePdf', {
            id: this.invoice.id,
            customer: {
                name: this.invoice.customer
            },
            invoice: {
                invoice_uuid: this.invoice.uuid
            }
        })
    },
      downloadExcel() {
          this.$store.dispatch('getOrderItemExcel', this.invoice)
      }
  }
}
</script>

<style>

</style>